import type { Database, SqlValue, Statement } from 'sql.js';
import type BaseLayer from 'ol/layer/Base';
import type Feature from 'ol/Feature';
import initSqlJs from 'sql.js';
import VectorLayer from 'ol/layer/Vector';

import {
    type SearchGeoJSONFeature,
    SearchGeoJSONFeatureTypeEnum,
    SearchGeoJSONLayerFormatEnum,
} from '@connect-field/client/sdk/generated';
import type { SearchResult } from '@connect-field/client/pages/home/search/SearchView.vue';

class SearchDB {
    public _databaseIsLoaded: boolean;
    public _db!: Database;
    public query!: Statement;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public tableNames: Array<any>;

    public constructor() {
        this._databaseIsLoaded = false;
        this.tableNames = [];
    }

    public close(): void {
        if (!this._db) {
            return;
        }
        this._db.close();
    }

    public async openDB(array: Uint8Array): Promise<void> {
        try {
            const SQL = await initSqlJs({
                locateFile: () => `/sql-wasm-1.11.0.wasm`,
            });

            this._db = new SQL.Database(array);

            const rows = this._db.exec(
                "SELECT name FROM sqlite_master WHERE type = 'table' AND name NOT LIKE 'sqlite_%'",
            );

            if (!rows || rows.length < 1 || !rows[0].values) {
                throw new Error('Hard problem with the sqlite file');
            }
            this.tableNames = rows[0].values.reduce((acc, arr) => {
                return acc.concat(arr);
            }, []);
            this._databaseIsLoaded = true;
        } catch (error: unknown) {
            console.error(error);
            alert('Problème avec le fichier de recherche hors ligne');
            throw error;
        }
    }
}

let searchDB: SearchDB;

export async function loadSearchDatabase(arrayBuffer: Uint8Array): Promise<void> {
    unloadFile();
    await searchDB.openDB(arrayBuffer);
}

export function unloadFile(): void {
    if (searchDB) {
        searchDB.close();
    }
    searchDB = new SearchDB();
}

export function search(
    table: string,
    text: string,
):
    | undefined
    | Array<{
          layerAlias: string;
          searchAttribute: string | null;
          sid: string;
          table: string;
      }> {
    if (searchDB && searchDB._databaseIsLoaded) {
        if (searchDB.tableNames.includes(table)) {
            // In SQLite, LIKE is case-insensitive
            const query = searchDB._db.exec(
                `SELECT * FROM ${table} WHERE keyword LIKE '%${text}%' ORDER BY keyword LIMIT 0,5`,
            );

            if (query.length > 0) {
                const { values } = query[0];

                return values.map((value: Array<SqlValue>) => {
                    const [sid, searchAttribute, , layerAlias] = value;

                    return {
                        layerAlias: (layerAlias || '').toString(),
                        searchAttribute: (searchAttribute || '').toString(),
                        sid: (sid || '').toString(),
                        table: table,
                    };
                });
            }
        }

        return [];
    }

    return undefined;
}

export interface RawResultInterface {
    layerAlias: string;
    searchAttribute: string | null;
    sid: string;
    table: string;
}

export function getFeaturesFromSearch(
    layersList: Array<BaseLayer>,
    rawResults: Array<RawResultInterface>,
): Array<SearchResult> {
    const resultFeatures: Array<SearchResult> = [];

    rawResults.forEach((rawResult: RawResultInterface) => {
        const layer = layersList.find((layer: BaseLayer) => layer.getProperties().table.includes(rawResult.table));

        if (!(layer && layer instanceof VectorLayer)) {
            return;
        }
        const layerSource = layer.getSource();

        if (!layerSource) {
            return;
        }

        const layerFeatures = layerSource.getFeatures();

        if (layerFeatures.length < 1) {
            return;
        }

        const feature = layerFeatures.find((feature: Feature) => {
            return feature.getId()?.toString() === rawResult?.sid?.toString();
        });

        if (!feature) {
            return;
        }

        const layerId = layer.getProperties().id;
        const layerFeatureData = buildFeatureData(feature, layerId, rawResult);

        if (!layerFeatureData) {
            return;
        }

        const searchResult: SearchResult = {
            feature: layerFeatureData,
            layerAlias: rawResult.layerAlias,
            layerFormat: SearchGeoJSONLayerFormatEnum.GEOJSON,
            layerId: layerId,
        };

        resultFeatures.push(searchResult);
    });

    return resultFeatures;
}

function buildFeatureData(
    feature: Feature,
    layerId: number,
    rawResult: RawResultInterface,
): SearchGeoJSONFeature | undefined {
    const { searchAttribute, layerAlias } = rawResult;

    return {
        geometry: feature.getGeometry(),
        id: parseInt((feature.getId() ?? 0).toString(), 10),
        layerAlias,
        layerFormat: SearchGeoJSONLayerFormatEnum.GEOJSON,
        layerId: layerId,
        properties: feature.getProperties(),
        searchAttribute: searchAttribute ?? '',
        type: SearchGeoJSONFeatureTypeEnum.FEATURE,
        uniqueLayerFeatureId: parseInt(`${layerId}${feature.getId()}`, 10),
    };
}
