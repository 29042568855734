import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
import axios from 'axios';
import { get as getProjection } from 'ol/proj';
import { getWidth } from 'ol/extent';
import { OSM } from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import WMTSTileGrid from 'ol/tilegrid/WMTS';

export function generateOSMBackground(): TileLayer<OSM> {
    return new TileLayer({
        className: 'layer-osm',
        properties: {
            alias: 'Défaut',
            global: true,
            isBackgroundLayer: true,
            name: 'background_OSM',
            online: true,
            type: 'default',
        },
        source: new OSM(), // tiles are served by OpenStreetMap
        visible: true,
        zIndex: -1,
    });
}

export async function generateIGNBackground(): Promise<TileLayer<WMTS>> {
    const parser = new WMTSCapabilities();
    const response = await axios.get('https://data.geopf.fr/annexes/ressources/wmts/ortho.xml', {
        timeout: 10000,
    });

    if (!response.data) {
        throw new Error('The object response.data is empty or unknown');
    }

    const result = parser.read(response.data);

    const IGNOptions = optionsFromCapabilities(result, {
        layer: 'ORTHOIMAGERY.ORTHOPHOTOS',
    });
    if (!IGNOptions) {
        throw new Error('[MapInstance] Options is null');
    }

    const resolutions = [];
    const matrixIds = [];
    const proj3857 = getProjection('EPSG:3857');
    if (!proj3857) {
        throw new Error('[MapInstance] Projection is null');
    }
    const maxResolution = getWidth(proj3857.getExtent()) / 256;

    for (let i = 0; i < 20; i++) {
        matrixIds[i] = i.toString();
        resolutions[i] = maxResolution / Math.pow(2, i);
    }

    IGNOptions.tileGrid = new WMTSTileGrid({
        matrixIds: matrixIds,
        origin: [-20037508, 20037508],
        resolutions: resolutions,
    });

    return new TileLayer({
        className: 'layer-ign',
        properties: {
            alias: 'Satellite',
            global: true,
            isBackgroundLayer: true,
            name: 'background_IGN',
            online: true,
            type: 'satellite',
        },
        source: new WMTS(IGNOptions),
        visible: false,
        zIndex: -1,
    });
}
