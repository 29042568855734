import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    amont: 'Amont',
    arrimage_cable: 'Arrimage de câble',
    cb_love: 'Câble Love',
    cable_sig: 'Câble SIG',
    cable_terrain: 'Câble terrain',
    capaamont: 'Capacité',
    cassette: 'Cassette',
    cb_fix: 'Fixation du boîtier',
    code: 'Code de la boîte',
    collecteur: 'Collecteur (que si pb de ROP)',
    continuite_optique: 'Continuité optique',
    coord_gps: 'Coordonnée GPS',
    criticite_remarque: 'Criticité remarque',
    date_emission_rsv: 'Date émission',
    date_mco: 'Date MCO',
    distance: 'Distance (que si pb de continuité)',
    emprise: 'Emprise',
    entree_cable: 'Entrée de câble',
    environnement: 'Environnement',
    infrastructure: 'Infrastructure',
    etat_boitier: 'État du boîtier',
    eti_boite: 'Étiquette de boîte',
    eti_cable: 'Étiquette de câble',
    etiquette_boitier: 'Étiquette de boîtier',
    etiquette_cable: 'Étiquette de câble',
    fibres: 'Fibre',
    fixation_boitier: 'Fixation du boîtier',
    gestionnai: 'Gestionnaire',
    id_parent: 'Id Parent',
    info_boitier_sig: 'Information boîtier SIG',
    intb_ens: 'État du boîtier',
    ligne: 'Ligne (que si pb de ROP)',
    plan_boite: 'Plan de boite',
    proprietai: 'Propriétaire',
    rec_boitier_real: 'Recette du boîtier réalisable',
    remarque_supplementaire: 'Remarque supplémentaire',
    sst: 'sous_traitant',
    statut_audit: 'Statut audit',
    support: 'Support',
    tampon_cadre: 'Infra Ptech',
    tiroir: 'Tiroir (que si pb de ROP)',
    tubes: 'Tubes',
    type_gest_pt: 'Type gestionnaire du Ptech',
    type_struc: 'Type structure',
    type_struc_pt: 'Type Structure Ptech',
    urlexcel: 'URL Excel',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    arrimage_cable: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Câble mal/non arrimé', value: 'Câble mal/non arrimé' },
        { label: 'Porteurs/kevlar HS', value: 'Porteurs/kevlar HS' },
    ],
    cable_sig: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Capacité de câble incohérente entre terrain/SIG',
            value: 'Capacité de câble incohérente entre terrain/SIG',
        },
        { label: 'Nom et/ou code du câble erroné(s) sur SIG', value: 'Nom et/ou code du câble erroné(s) sur SIG' },
    ],
    cable_terrain: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Câble non fixé', value: 'Câble non fixé' },
        { label: 'Goulotte demi-lune absente/détériorée', value: 'Goulotte demi-lune absente/détériorée' },
        {
            label: 'Lovage de câble trop court (impossible de sortir le boîtier)',
            value: 'Lovage de câble trop court (impossible de sortir le boîtier)',
        },
        {
            label: 'Cable détérioré / mauvais rayon de courbure (si contrainte réflecto)',
            value: 'Cable détérioré / mauvais rayon de courbure (si contrainte réflecto)',
        },
    ],
    cassette: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Cassette(s) détériorée(s)', value: 'Cassette(s) détériorée(s)' },
    ],
    continuite_optique: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Pas de Continuité Optique', value: 'Pas de Continuité Optique' },
        { label: 'Route Optique erronée', value: 'Route Optique erronée' },
    ],
    coord_gps: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Mauvaise position GPS', value: 'Mauvaise position GPS' },
    ],
    criticite_remarque: [
        { label: 'Critique', value: 'Critique' },
        { label: 'Majeur', value: 'Majeur' },
        { label: 'Mineur', value: 'Mineur' },
        { label: 'Remarque', value: 'Remarque' },
    ],
    entree_cable: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Entrées de câble mal/non réalisées', value: 'Entrées de câble mal/non réalisées' },
    ],
    etat_boitier: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Boîtier détérioré', value: 'Boîtier détérioré' },
        { label: 'Système de fermeture du boîtier HS', value: 'Système de fermeture du boîtier HS' },
        { label: "Boitier non adapté à l'infra", value: "Boitier non adapté à l'infra" },
        { label: 'Boitier non adapté au raccordement', value: 'Boitier non adapté au raccordement' },
        { label: 'Boîtier non étanche', value: 'Boîtier non étanche' },
    ],
    etiquette_boitier: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Étiquette boîtier absente/détériorée', value: 'Étiquette boîtier absente/détériorée' },
        {
            label: 'Étiquette boîtier incohérente entre terrain/SIG',
            value: 'Étiquette boîtier incohérente entre terrain/SIG',
        },
    ],
    etiquette_cable: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Une étiquette de câble absente/détériorée', value: 'Une étiquette de câble absente/détériorée' },
        {
            label: 'Plusieurs étiquettes de câble absentes/détériorées',
            value: 'Plusieurs étiquettes de câble absentes/détériorées',
        },
        {
            label: 'Étiquette(s) de câble incohérente(s) entre terrain/PdB',
            value: 'Étiquette(s) de câble incohérente(s) entre terrain/PdB',
        },
        { label: 'Étiquettes de câble inversées', value: 'Étiquettes de câble inversées' },
        { label: 'Capa de câble incohérente entre terrain/PdB', value: 'Capa de câble incohérente entre terrain/PdB' },
    ],
    fibres: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Fibre(s) cassée(s)/trop courte(s)/inexploitable(s)',
            value: 'Fibre(s) cassée(s)/trop courte(s)/inexploitable(s)',
        },
        { label: 'Épissure(s) non conforme(s)', value: 'Épissure(s) non conforme(s)' },
        {
            label: "Lovage de fibre ne respecte pas les règles de l'art",
            value: "Lovage de fibre ne respecte pas les règles de l'art",
        },
    ],
    fixation_boitier: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Boîtier mal/non fixé en souterrain', value: 'Boîtier mal/non fixé en souterrain' },
        { label: 'Boîtier mal/non fixé en aérien', value: 'Boîtier mal/non fixé en aérien' },
    ],
    info_boitier_sig: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Référence (OFMC, 3M T0, etc…) du boîtier erronée sur SIG',
            value: 'Référence (OFMC, 3M T0, etc…) du boîtier erronée sur SIG',
        },
    ],
    plan_boite: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Incohérence STATUT terrain / PdB (stockée/passage/épissurée)',
            value: 'Incohérence STATUT terrain / PdB (stockée/passage/épissurée)',
        },
        { label: 'Incohérence SOUDURE terrain / PdB', value: 'Incohérence SOUDURE terrain / PdB' },
        {
            label: 'Incohérence POSITION FIBRE en cassette terrain / PdB',
            value: 'Incohérence POSITION FIBRE en cassette terrain / PdB',
        },
        { label: 'Fibres mortes non modélisées', value: 'Fibres mortes non modélisées' },
        { label: 'Câble supplémentaire dans la boite', value: 'Câble supplémentaire dans la boite' },
    ],
    rec_boitier_real: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: "Demande d'accès nécessaire", value: "Demande d'accès nécessaire" },
        { label: 'Arrêté de circulation nécessaire', value: 'Arrêté de circulation nécessaire' },
        { label: 'Véhicule stationné sur la chambre', value: 'Véhicule stationné sur la chambre' },
        { label: 'Point technique non trouvé', value: 'Point technique non trouvé' },
        { label: 'Audit non réalisable - validé par photo sst', value: 'Audit non réalisable - validé par photo sst' },
        { label: 'Boîtier non posé', value: 'Boîtier non posé' },
        { label: 'Chambre sous enrobée sur chaussée', value: 'Chambre sous enrobée sur chaussée' },
        { label: 'Chambre sous enrobée sur trottoir', value: 'Chambre sous enrobée sur trottoir' },
    ],
    statut_audit: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
        { label: 'REAUDIT', value: 'REAUDIT' },
    ],
    tampon_cadre: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Tampon ou cadre absent/détérioré/scellé', value: 'Tampon ou cadre absent/détérioré/scellé' },
        {
            label: "Bandeau Vert posé par la QR - Etude + pose à réaliser sur chemin d'adduction",
            value: "Bandeau Vert posé par la QR - Etude + pose à réaliser sur chemin d'adduction",
        },
        { label: 'Appui non conforme', value: 'Appui non conforme' },
    ],
    tubes: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Tube(s) détérioré(s)/pincé(s)/inexploitable(s)',
            value: 'Tube(s) détérioré(s)/pincé(s)/inexploitable(s)',
        },
    ],
    type_gest_pt: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Propriétaire et/ou gestionnaire du point technique erroné(s)',
            value: 'Propriétaire et/ou gestionnaire du point technique erroné(s)',
        },
    ],
    type_struc_pt: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'CHAMBRE sur le terrain', value: 'CHAMBRE sur le terrain' },
        { label: 'APPUI AERIEN sur le terrain', value: 'APPUI AERIEN sur le terrain' },
        { label: 'ANCRAGE FACADE sur le terrain', value: 'ANCRAGE FACADE sur le terrain' },
        { label: 'IMMEUBLE sur le terrain', value: 'IMMEUBLE sur le terrain' },
    ],
};
